"use client";
import useUser from "../hooks/useUser";
import ClientApiProvider from "./ApiProvider";
import UserProvider from "./UserProvider";
import { Loader } from "../components/Loader/Loader";

const Providers = ({ children }) => {
  const { user } = useUser();
  return (
    <UserProvider>
      <ClientApiProvider>
        {user ? <>{children}</> : <Loader />}
      </ClientApiProvider>
    </UserProvider>
  );
};

export default Providers;

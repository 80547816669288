"use client";
import { createContext, useEffect, useState } from "react";
import { ApiClient } from "../api/api";
import useUser from "../hooks/useUser";

export const ClientApiContext = createContext({
  api: null,
});

const ClientApiProvider = ({ children }) => {
  const [api] = useState(
    new ApiClient(process.env.REACT_APP_SITE_BACK_BASE_URL)
  );

  const { user, refreshTokens } = useUser();

  api.refreshTokens = refreshTokens;

  useEffect(() => {
    api.currentToken = user?.accessToken;
    api.accessToken = user?.accessToken;
    api.refreshToken = user?.refreshToken;
  }, [api, user?.accessToken, user?.refreshToken]);

  return (
    <ClientApiContext.Provider value={{ api }}>
      {children}
    </ClientApiContext.Provider>
  );
};

export default ClientApiProvider;

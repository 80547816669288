import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru.js";

const LicenseAgreementView = ({
  licenseText,
  setLicenseText,
  handleUpdateLicense,
  error,
  isError,
  handleCloseIsError,
}) => {
  const handleChangeLicenseText = (e, editor) => {
    setLicenseText(editor.getData());
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "80%",
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "blockQuote",
              "|",
              "numberedList",
              "bulletedList",
            ],
            language: "ru",
          }}
          data={licenseText}
          onChange={handleChangeLicenseText}
        />

        <LoadingButton
          className="license-update"
          variant="outlined"
          startIcon={<ArrowUpwardIcon />}
          loadingPosition="start"
          onClick={handleUpdateLicense}
        >
          Обновить соглашение
        </LoadingButton>
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default LicenseAgreementView;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Typography,
  Button,
  Input,
  Modal,
  IconButton,
} from "@mui/material";
import { Loader } from "../../components/Loader/Loader";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FeedIcon from "@mui/icons-material/Feed";
import KeyIcon from "@mui/icons-material/Key";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import useUser from "../../hooks/useUser";
import CloseIcon from "@mui/icons-material/Close";

const UserIdView = ({
  user,
  setUserInfo,
  activeKey,
  isError,
  setIsError,
  error,
  setError,
}) => {
  const navigate = useNavigate();
  const [profileAvatar, setProfileAvatar] = useState(null);
  const [isChangeData, setIsChangeData] = useState(true);
  const [changedUserInfo, setChangedUserInfo] = useState(null);

  const { adminUpdateProfile } = useUser();

  useEffect(() => {
    if (user) {
      if (user.avatarUrl) {
        setProfileAvatar(`http://localhost:3002/file/${user.avatarUrl}`);
      }
      setChangedUserInfo(user);
      setIsError(false);
    }
  }, [user]);

  const handleIsChangeData = () => {
    setIsChangeData(!isChangeData);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  const handleChange = prop => event => {
    setError("");
    const item = prop.toString().toLowerCase();
    setChangedUserInfo({ ...changedUserInfo, [item]: event.target.value });
  };

  const handleCheckProjects = () => {
    navigate(`/projects/user/${user._id}`);
  };

  const handleCheckKeys = () => {
    navigate(`/keys/user/${user._id}`);
  };

  const handleCheckTransactions = () => {
    navigate(`/transactions/user/${user._id}`);
  };

  const handleChangeData = async () => {
    if (
      user !== changedUserInfo &&
      changedUserInfo.name !== "" &&
      changedUserInfo.organization !== ""
    ) {
      const newData = {
        name: changedUserInfo.name,
        organization: changedUserInfo.organization,
      };

      await adminUpdateProfile(changedUserInfo._id, newData)
        .then(res => {
          setUserInfo(changedUserInfo);
        })
        .catch(res => {})
        .finally(() => {
          setIsChangeData(true);
        });
    } else {
      setError("Нет изменения в данных или не все поля заполнены");
      setIsError(true);
      setIsChangeData(true);
    }
  };

  const stringAvatar = name => {
    if (name.split(" ")[1]) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        children: `${name.split(" ")[0][0]}`,
      };
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",

        backgroundColor: "#f2f2f2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "80%",
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {changedUserInfo ? (
          <>
            <Box
              className="user-info"
              sx={{
                width: {
                  lg: "60%",
                  md: "90%",
                  sm: "80%",
                  xs: "100%",
                },
                height: "50%",
                p: "1rem",
                background: "#fff",
                display: "flex",
                flexDirection: {
                  md: "row",
                  xs: "column",
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={profileAvatar}
                {...stringAvatar(user.name)}
                sx={theme => ({
                  width: {
                    md: 120,
                    xs: 60,
                  },
                  height: {
                    md: 120,
                    xs: 60,
                  },
                  borderWidth: "1px",
                  borderColor: theme.palette.primary.main,
                  borderStyle: "solid",
                })}
              />

              <Box
                sx={{
                  width: {
                    lg: "80%",
                    xs: "100%",
                  },
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: {
                      lg: "80%",
                      xs: "100%",
                    },
                    p: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      mb: "0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ width: "40%", mb: 0 }}>
                      Имя пользователя:
                    </Typography>
                    <Input
                      disabled={isChangeData}
                      value={changedUserInfo?.name}
                      sx={{ width: "40%", m: 0 }}
                      onChange={handleChange("name")}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      mb: "0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ width: "40%", mb: 0 }}>
                      Организация:
                    </Typography>
                    <Input
                      disabled={isChangeData}
                      value={changedUserInfo?.organization}
                      sx={{ width: "40%", m: 0 }}
                      onChange={handleChange("organization")}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      mb: "0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ width: "40%", mb: 0 }}>Email:</Typography>
                    <Input
                      disabled
                      value={changedUserInfo?.email}
                      sx={{ width: "40%", m: 0 }}
                    />
                  </Box>

                  {activeKey ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          mb: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ width: "40%", mb: 0 }}>
                          Дата активации ключа:
                        </Typography>
                        <Input
                          disabled
                          type="date"
                          value={activeKey?.createdAt.split("T")[0]}
                          sx={{ width: "40%", m: 0 }}
                        />
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          mb: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ width: "40%", mb: 0 }}>
                          Осталось проверок:
                        </Typography>
                        <Input
                          disabled
                          value={activeKey?.calculationsRemained}
                          sx={{ width: "40%", m: 0 }}
                        />
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        mb: "0.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          mt: 5,
                          mb: 0,
                          textAlign: "center",
                        }}
                      >
                        Нет активного ключа
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              className="user-buttons-change"
              sx={{
                width: {
                  md: "100%",
                  sm: "80%",
                  xs: "100%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {isChangeData ? (
                  <Button
                    className="user-edit"
                    variant="outlined"
                    startIcon={<BorderColorIcon />}
                    onClick={handleIsChangeData}
                  >
                    Редактировать данные
                  </Button>
                ) : (
                  <>
                    <Button
                      className="user-edit"
                      variant="outlined"
                      onClick={handleIsChangeData}
                    >
                      Отменить
                    </Button>

                    <Button
                      className="user-edit"
                      variant="outlined"
                      onClick={handleChangeData}
                    >
                      Подтвердить
                    </Button>
                  </>
                )}
              </Box>
            </Box>

            <Box
              className="user-links"
              sx={{
                width: {
                  md: "100%",
                  sm: "80%",
                  xs: "100%",
                },
                height: "50%",
                background: "#fff",
                display: "flex",
                flexDirection: {
                  sm: "row",
                  xs: "column",
                },
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Button
                className="user-projects"
                onClick={() => handleCheckProjects()}
                variant="outlined"
                startIcon={<FeedIcon />}
              >
                Посмотреть проекты
              </Button>

              <Button
                className="user-keys"
                onClick={() => handleCheckKeys()}
                variant="outlined"
                startIcon={<KeyIcon />}
              >
                Ключи
              </Button>

              <Button
                className="user-edit"
                onClick={() => handleCheckTransactions()}
                variant="outlined"
                startIcon={<SwapHorizIcon />}
              >
                Транзакции
              </Button>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserIdView;

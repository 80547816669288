import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import useKeys from "../../hooks/useKeys";
import KeysTable from "../../components/KeysTable/KeysTable";
import CloseIcon from "@mui/icons-material/Close";

const UserKeysView = ({ userInfo }) => {
  const [orderDirection, setOrderDirection] = useState("asc");
  const [direction, setDirection] = useState(1);
  const [valueToOrderBy, setValueToOrderBy] = useState("key");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const searchParams = useParams();
  const [keys, setKeys] = useState(null);
  const [calculations, setCalculations] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  const itemsTableHeadRows = [
    {
      name: "Ключ",
      value: "key",
    },
    {
      name: "Расчётов всего",
      value: "calculationsCount",
    },
    {
      name: "Расчётов осталось",
      value: "calculationsRemained",
    },
    {
      name: "Пользователь",
      value: "ownerId",
    },
    {
      name: "Добавлен",
      value: "createdAt",
    },
    {
      name: "Обновлен",
      value: "updatedAt",
    },
  ];

  const { getUserKeys, createKey } = useKeys();

  useEffect(() => {
    if (userInfo) {
      getUserKeys(
        searchParams.id,
        page,
        rowsPerPage,
        JSON.stringify({
          [valueToOrderBy]: direction,
        })
      )
        .then(res => {
          setKeys(res.data);
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            404: "Ключи не найдены",
          };

          setError(errorsMessages[res?.response?.status]);
          setKeys({});
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [userInfo, valueToOrderBy, orderDirection, page, rowsPerPage, isLoading]);

  const handleCheckUserCard = userId => {
    navigate(`/user/${userId}`);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  const handleOpenAddKey = () => {
    setIsOpen(true);
  };

  const handleCloseIsOpen = () => {
    setError("");
    setCalculations(0);
    setIsOpen(false);
  };

  const handleChange = e => {
    setError("");

    if (e.target.value.match(/[0-9]+/)) {
      setCalculations(e.target.value);
    } else if (!e.target.value.match(/[0-9]+/)) {
      setError(`Число не может быть меньше 0`);
    } else {
      setError(`Нельзя ввести символы: e, + или - `);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (calculations !== 0) {
      const keyData = {
        calculationsCount: Number(calculations),
        ownerId: userInfo._id,
      };

      setCalculations(0);

      await createKey(keyData)
        .then(res => {
          setError("");
          setIsOpen(false);
          setIsLoading(true);
        })
        .catch(res => {
          const errorsMessages = {
            409: "У пользователя уже есть активный ключ",
            401: "Неверные данные для авторизации",
          };

          setIsOpen(false);
          setError(errorsMessages[res.response.status]);
          setIsError(true);
        });
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        onClick={() => handleOpenAddKey()}
        sx={{
          width: "200px",
          border: "1px solid",
          marginRight: {
            md: "20%",
            sm: "5%",
          },
          borderRadius: { sm: "15px" },
          background: "white",
          alignSelf: "flex-end",
        }}
      >
        Добавить ключ
      </Button>

      <Box
        sx={{
          maxWidth: {
            lg: "90%",
            sm: "85%",
          },
          ml: {
            md: 0,
            sm: "20px",
          },
          mt: {
            md: 0,
            sm: "20%",
          },
          maxHeight: { lg: "90%", sm: "80%" },
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <KeysTable
          HeadRows={itemsTableHeadRows}
          BodyRows={keys}
          onClickUser={handleCheckUserCard}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          setDirection={setDirection}
          valueToOrderBy={valueToOrderBy}
          setValueToOrderBy={setValueToOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={isOpen}
        onClose={handleCloseIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "30%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h7"
              sx={{
                mb: "1rem",
              }}
            >
              Добавление ключа пользователю
            </Typography>

            <Grid
              item
              xs={12}
              lg={4}
              sx={() => ({
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              })}
            >
              <TextField
                value={calculations}
                label="Колличество расчётов"
                variant="outlined"
                type="number"
                min="0"
                placeholder={`Введите колличество расчётов`}
                InputProps={{ inputProps: { min: 0 } }}
                sx={theme => ({
                  width: "100%",
                })}
                onChange={e => handleChange(e)}
              />
            </Grid>

            <Box
              sx={{
                height: "10%",
              }}
            >
              {error && (
                <Typography
                  variant="h7"
                  sx={{
                    mb: "1rem",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Button
                onClick={() => handleCloseIsOpen()}
                sx={{
                  width: "200px",
                  border: "1px solid",
                  marginRight: "10%",
                  borderRadius: { sm: "15px" },
                  background: "white",
                  alignSelf: "flex-end",
                }}
              >
                Отмена
              </Button>

              <Button
                type="submit"
                sx={{
                  width: "200px",
                  border: "1px solid",
                  borderRadius: { sm: "15px" },
                  background: "white",
                  alignSelf: "flex-end",
                }}
              >
                Добавить
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserKeysView;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../layouts/Layout";
import useProject from "../../hooks/useProject";
import ProjectIdView from "../../views/Projects/ProjectIdView";

const ProjectCard = () => {
  const searchParams = useParams();
  const [projectInfo, setProject] = useState(null);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const { getProject } = useProject();

  useEffect(() => {
    if (searchParams.id)
      getProject(searchParams.id)
        .then(res => {
          setError("");
          setIsError(false);
          setProject(res.data);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            400: "Проект не найден",
            404: "Проект не найден",
          };

          setError(errorsMessages[res?.response?.status]);
          setIsError(true);
        });
  }, [searchParams.id]);

  return (
    <Layout
      title={`Проект: ${projectInfo?.name ? projectInfo?.name : "Загрузка..."}`}
    >
      <ProjectIdView
        project={projectInfo}
        isError={isError}
        setIsError={setIsError}
        error={error}
        setError={setError}
      />
    </Layout>
  );
};

export default ProjectCard;

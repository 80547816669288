import { Box, TableCell } from "@mui/material";

const StyledTableCell = ({ onClick, id, children, sx }) => {
  return (
    <TableCell
      align="right"
      sx={{
        fontFamily: "Open Sans !important",
        fontSize: {
          md: "1rem !important",
        },
        fontWeight: "600 !important",
        textAlign: "start",
        p: "1rem",
        ...(onClick && {
          ":hover": { backgroundColor: "rgba(0,0,0,0.1)", cursor: "pointer" },
        }),
        ...sx,
      }}
      onClick={onClick ? () => onClick(id) : null}
    >
      <Box sx={{ display: "flex" }}>{children}</Box>
    </TableCell>
  );
};

export default StyledTableCell;

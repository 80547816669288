import Layout from "../../layouts/Layout";
import KeysView from "../../views/Keys/KeysView";

const Keys = () => {
  return (
    <Layout title={"Ключи"}>
      <KeysView />
    </Layout>
  );
};

export default Keys;

"use client";
import { useContext } from "react";
import { ClientApiContext } from "../providers/ApiProvider";

const useProject = () => {
  const { api } = useContext(ClientApiContext);

  const fetchUsersProjects = async (page, limit, sort) => {
    const response = await api.project.getAllProjects(page, limit, sort);

    return response;
  };

  const fetchUserProjects = async (id, page, limit, sort) => {
    const response = await api.project.getUserProjects(id, page, limit, sort);

    return response;
  };

  const deleteProject = async id => {
    const response = await api.project.deleteProject(id);

    return response;
  };

  const getProject = async id => {
    const response = await api.project.getProject(id);

    return response;
  };

  return {
    fetchUsersProjects,
    fetchUserProjects,
    deleteProject,
    getProject,
  };
};

export default useProject;

import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import useUser from "../hooks/useUser";
import { UserContext } from "../providers/UserProvider";
import SideBar from "../components/SideBar/SideBar";
import { Loader } from "../components/Loader/Loader";

const Layout = ({ children, title }) => {
  const navigate = useNavigate();
  const { loading } = useContext(UserContext);
  const { user, logout, getUserInfo } = useUser();

  useEffect(() => {
    if (user) {
      getUserInfo()
        .then(res => {
          if (
            !res.data.roles.includes("admin") &&
            !res.data.roles.includes("superAdmin")
          ) {
            logout();
          }
        })
        .catch(res => {
          navigate("/authorization");
        });
    }
  }, [user]);

  if (loading) {
    return <Loader />;
  }

  // if (!user) {
  //   router.push("/authorization");
  // }

  const setTitle = title => {
    useEffect(() => {
      document.title = title;
    }, [title]);
  };

  return (
    <>
      {setTitle(title)}

      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <SideBar title={title} />
        {children}
      </Box>
    </>
  );
};

export default Layout;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useProject from "../../../hooks/useProject";
import Layout from "../../../layouts/Layout";
import ProjectTransactionsView from "../../../views/Transactions/ProjectTransactionsView";

const ProjectTransactions = () => {
  const searchParams = useParams();
  const [projectInfo, setProjectInfo] = useState(null);
  const { user } = useUser();
  const { getProject } = useProject();

  useEffect(() => {
    if (user) {
      getProject(searchParams.id)
        .then(res => {
          setProjectInfo(res.data);
        })
        .catch(res => {});
    }
  }, [user, searchParams.id]);

  return (
    <Layout
      title={`Транзакции по проекту: ${
        projectInfo?.name ? projectInfo.name : "Загрузка..."
      }`}
    >
      <ProjectTransactionsView project={projectInfo} />
    </Layout>
  );
};

export default ProjectTransactions;

"use client";
import { useContext } from "react";
import { ClientApiContext } from "../providers/ApiProvider";

const useKeys = () => {
  const { api } = useContext(ClientApiContext);

  const getAllKeys = async (page, limit, sort) => {
    const response = await api.key.getAllKeys(page, limit, sort);

    return response;
  };

  const getUserKeys = async (id, page, limit, sort) => {
    const response = await api.key.getUserKeys(id, page, limit, sort);

    return response;
  };

  const getUserActiveKey = async id => {
    const response = await api.key.getUserActiveKey(id);

    return response;
  };

  const createKey = async data => {
    const response = await api.key.createKey(data);

    return response;
  };

  return { getAllKeys, getUserKeys, getUserActiveKey, createKey };
};

export default useKeys;

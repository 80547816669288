import Layout from "../../layouts/Layout";
import TransactionsView from "../../views/Transactions/TransactionsView";

const Transactions = () => {
  return (
    <Layout title={"Транзакции"}>
      <TransactionsView />
    </Layout>
  );
};

export default Transactions;

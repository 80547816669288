"use client";
import { useContext } from "react";
import { ClientApiContext } from "../providers/ApiProvider";

const useTransaction = () => {
  const { api } = useContext(ClientApiContext);

  const getAllTransactions = async (page, limit, sort) => {
    const response = await api.transaction.getAllTransactions(
      page,
      limit,
      sort
    );

    return response;
  };

  const getProjectTransactions = async (id, page, limit, sort) => {
    const response = await api.transaction.getProjectTransactions(
      id,
      page,
      limit,
      sort
    );

    return response;
  };

  const getUserTransactions = async (id, page, limit, sort) => {
    const response = await api.transaction.getUserTransactions(
      id,
      page,
      limit,
      sort
    );

    return response;
  };

  const getTransaction = async id => {
    const response = await api.transaction.getTransaction(id);

    return response;
  };

  return {
    getAllTransactions,
    getProjectTransactions,
    getUserTransactions,
    getTransaction,
  };
};

export default useTransaction;

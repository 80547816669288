import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import Layout from "../../../layouts/Layout";
import UserProjectsView from "../../../views/Users/UserProjectsView";

const UserProjects = () => {
  const searchParams = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const { user, adminGetProfile } = useUser();

  useEffect(() => {
    if (user) {
      adminGetProfile(searchParams.id)
        .then(res => {
          setUserInfo(res.data);
        })
        .catch(res => {});
    }
  }, [user, searchParams.id]);

  return (
    <Layout
      title={`Проекты пользователя: ${
        userInfo?.name ? userInfo.name : "Загрузка..."
      }`}
    >
      <UserProjectsView userInfo={userInfo} />
    </Layout>
  );
};

export default UserProjects;

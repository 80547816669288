import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Input,
  Typography,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Status from "../../components/Status/Status";
import FeedIcon from "@mui/icons-material/Feed";
import DownloadIcon from "@mui/icons-material/Download";
import { Loader } from "../../components/Loader/Loader";
import CloseIcon from "@mui/icons-material/Close";

const TransactionIdView = ({
  transaction,
  isError,
  setIsError,
  error,
  setError,
}) => {
  const navigate = useNavigate();
  const [isWait, setIsWait] = useState(false);
  const [downloadedData, setDownloadedData] = useState({
    projectId: "",
    узлы: null,
    провода: null,
    status: "",
    resultsJSON: "",
  });

  useEffect(() => {
    if (transaction) {
      setDownloadedData({
        projectId: transaction?.projectId,
        узлы: transaction?.state?.узлы,
        провода: transaction?.state?.провода,
        status: transaction?.status,
        resultsJSON: transaction?.output,
      });
    }
  }, [transaction]);

  const downloadFile = () => {
    const fileName = transaction?._id;
    const json = JSON.stringify(downloadedData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleCheckProject = id => {
    setIsWait(true);

    navigate(`/project/${id}`);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  const parseErrorOutput = output => {
    const errorArray = output.split("\r\n");

    return errorArray[errorArray.length - 1];
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",

        backgroundColor: "#f2f2f2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: {
            sm: "70%",
            xs: "90%",
          },
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {transaction ? (
          <>
            <Box
              className="transaction-info"
              sx={{
                width: {
                  md: "100%",
                  sm: "80%",
                  xs: "100%",
                },
                height: "50%",
                p: "1rem",
                background: "#fff",
                display: "flex",
                flexDirection: {
                  md: "row",
                  xs: "column",
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: {
                    lg: "70%",
                    md: "80%",
                    xs: "100%",
                  },
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Дата создания:
                  </Typography>
                  <Input
                    disabled
                    type="date"
                    value={transaction?.createdAt.split("T")[0]}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Последнее обновление:
                  </Typography>
                  <Input
                    disabled
                    type="date"
                    value={transaction?.updatedAt.split("T")[0]}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>Ключ:</Typography>
                  <Input
                    disabled
                    value={transaction?.key}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Имя пользователя:
                  </Typography>
                  <Input
                    disabled
                    value={transaction?.ownerId?.name}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Название проекта:
                  </Typography>
                  <Input
                    disabled
                    value={transaction?.projectId?.name}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>Статус:</Typography>

                  {transaction?.status === "success" ? (
                    <Status status={"Обработан"} />
                  ) : transaction?.status === "progress" ? (
                    <Status status={"Прогресс"} />
                  ) : !transaction?.status ? (
                    <Status status={"Не расчитан"} />
                  ) : (
                    <Status status={"Ошибка"} />
                  )}

                  <Input
                    disabled
                    value={
                      transaction?.status === "success"
                        ? "Обработан"
                        : transaction?.status === "progress"
                        ? "Прогресс"
                        : !transaction?.status
                        ? "Не расчитан"
                        : "Ошибка"
                    }
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                {transaction.status === "error" ? (
                  <Box
                    sx={{
                      width: "100%",
                      mb: "0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ width: "40%", mb: 0 }}>
                      Output:
                    </Typography>

                    <TextField
                      disabled
                      multiline
                      minRows={6}
                      maxRows={6}
                      value={parseErrorOutput(transaction?.output)}
                      sx={{ width: "40%", m: 0 }}
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>

            <Box
              className="transaction-links"
              sx={{
                width: {
                  md: "100%",
                  sm: "80%",
                  xs: "100%",
                },
                height: "50%",
                background: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                ...(transaction.status === "error"
                  ? { height: "30%" }
                  : { height: "50%" }),
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "50%",
                  background: "#fff",
                  display: "flex",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <LoadingButton
                  className="transaction-open"
                  variant="outlined"
                  startIcon={<FeedIcon />}
                  loading={isWait}
                  loadingPosition="start"
                  onClick={() =>
                    handleCheckProject(transaction?.projectId?._id)
                  }
                >
                  Перейти к проекту
                </LoadingButton>

                <LoadingButton
                  className="transaction-download"
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  loading={isWait}
                  loadingPosition="start"
                  onClick={downloadFile}
                >
                  Скачать данные
                </LoadingButton>
              </Box>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default TransactionIdView;

import Layout from "../../layouts/Layout";
import UsersView from "../../views/Users/UsersView";

const Users = () => {
  return (
    <Layout title={"Пользователи"}>
      <UsersView />
    </Layout>
  );
};

export default Users;

"use client";
import { useState, createContext, useEffect } from "react";

export const UserContext = createContext({
  loading: false,
  setLoading: () => {},
  user: {},
  setUser: () => {},
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserFromStorage = () => {
      try {
        const user = localStorage.getItem("user");
        if (user) {
          const userFromStorage = JSON.parse(user);
          setUser(userFromStorage);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getUserFromStorage();
  }, []);

  useEffect(() => {
    const saveUserToStorage = () => {
      if (user !== null) localStorage.setItem("user", JSON.stringify(user));
    };
    saveUserToStorage();
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, loading, setLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import Providers from "./providers/Providers";
import AdminLogin from "./pages/authorization/login";
import "./styles/globals.css";
import Users from "./pages/users";
import UserCard from "./pages/users/[userId]";
import ProjectCard from "./pages/projects/[projectId]";
import Keys from "./pages/keys";
import Transactions from "./pages/transactions";
import UserKeys from "./pages/keys/[userId]";
import AllProjects from "./pages/projects";
import ProjectTransactions from "./pages/transactions/project/[projectId]";
import TransactionCard from "./pages/transactions/transaction/[transactionId]";
import UserTransactions from "./pages/transactions/user/[userId]";
import UserProjects from "./pages/users/[userId]/projects";
import addAdmin from "./pages/addAdmin";
import SuperAdminCreate from "./pages/superAdminCreate";
import licenseAgreement from "./pages/licenseAgreement";

const App = () => {
  return (
    <Providers>
      <Routes>
        <Route path="/authorization" element={<AdminLogin />} />
        <Route path="/superAdminCreate" element={<SuperAdminCreate />} />

        <Route path="/users" element={<ProtectedRoute component={Users} />} />
        <Route
          path="/user/:id"
          element={<ProtectedRoute component={UserCard} />}
        />
        <Route
          path="/projects"
          element={<ProtectedRoute component={AllProjects} />}
        />
        <Route
          path="/project/:id"
          element={<ProtectedRoute component={ProjectCard} />}
        />
        <Route
          path="/projects/user/:id"
          element={<ProtectedRoute component={UserProjects} />}
        />
        <Route path="/keys" element={<ProtectedRoute component={Keys} />} />
        <Route
          path="/keys/user/:id"
          element={<ProtectedRoute component={UserKeys} />}
        />
        <Route
          path="/transactions"
          element={<ProtectedRoute component={Transactions} />}
        />
        <Route
          path="/transaction/:id"
          element={<ProtectedRoute component={TransactionCard} />}
        />
        <Route
          path="/transactions/project/:id"
          element={<ProtectedRoute component={ProjectTransactions} />}
        />
        <Route
          path="/transactions/user/:id"
          element={<ProtectedRoute component={UserTransactions} />}
        />
        <Route
          path="/addAdmin"
          element={<ProtectedRoute component={addAdmin} />}
        />
        <Route
          path="/licenseAgreement"
          element={<ProtectedRoute component={licenseAgreement} />}
        />
        <Route path="/app/index.html" />

        <Route path="*" element={<Navigate to="/authorization" replace />} />
      </Routes>
    </Providers>
  );
};

export default App;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useTransaction from "../../../hooks/useTransaction";
import Layout from "../../../layouts/Layout";
import TransactionIdView from "../../../views/Transactions/TransactionIdView";

const TransactionCard = () => {
  const navigate = useNavigate();
  const searchParams = useParams();
  const [transactionInfo, setTransactionInfo] = useState(null);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const { user } = useUser();
  const { getTransaction } = useTransaction();

  useEffect(() => {
    if (user && searchParams.id) {
      getTransaction(searchParams.id)
        .then(res => {
          setTransactionInfo(res.data);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            400: "Транзакция не найдена",
            404: "Транзакция не найдена",
          };

          setError(errorsMessages[res?.response?.status]);
          setIsError(true);
          navigate("/transactions");
        });
    }
  }, [user, searchParams.id]);

  return (
    <Layout
      title={`Транзакция: ${
        transactionInfo?._id ? transactionInfo._id : "Загрузка..."
      }`}
    >
      <TransactionIdView
        transaction={transactionInfo}
        isError={isError}
        setIsError={setIsError}
        error={error}
        setError={setError}
      />
    </Layout>
  );
};

export default TransactionCard;

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import StyledTableCell from "../TableCell/StyledTableCell";

const UsersTable = ({
  HeadRows,
  BodyRows,
  onClickUser,
  orderDirection,
  setOrderDirection,
  setDirection,
  valueToOrderBy,
  setValueToOrderBy,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const handleSortBy = item => {
    const isAscending = valueToOrderBy === item && orderDirection === "asc";
    setValueToOrderBy(item);
    setOrderDirection(isAscending ? "desc" : "asc");
    setDirection(isAscending ? "-1" : "1");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(1);
  };

  return (
    <>
      <TableContainer
        sx={{
          maxWidth: "95%",
          maxHeight: "95%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Table stickyHeader sx={{ width: "90%", height: "90%" }}>
          <TableHead>
            <TableRow>
              {HeadRows.map(item => (
                <StyledTableCell key={item.value}>
                  <TableSortLabel
                    active={valueToOrderBy === item.value}
                    direction={
                      valueToOrderBy === item.value ? orderDirection : "asc"
                    }
                    onClick={() => handleSortBy(item.value)}
                  >
                    {item.name}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {BodyRows?.results?.map(row => (
              <TableRow key={row._id}>
                <StyledTableCell onClick={onClickUser} id={row._id}>
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.organization}</StyledTableCell>
                <StyledTableCell>{row.roles.join(", ")}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={BodyRows?.resultCount ? BodyRows?.resultCount : 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default UsersTable;

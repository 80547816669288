import { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import LicenseAgreementView from "../views/LicenseAgreement";
import useAppContent from "../hooks/useAppContent";

const licenseAgreement = () => {
  const [licenseText, setLicenseText] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const { getLicense, updateLicense } = useAppContent();

  useEffect(() => {
    getLicense()
      .then(res => {
        setLicenseText(res.data.licenseText);
      })
      .catch(res => {});
  }, []);

  const handleUpdateLicense = () => {
    updateLicense({ licenseText: licenseText })
      .then(res => {
        setError("Текст лицензионного соглашения обновлен");
        setIsError(true);
      })
      .catch(res => {
        const errorsMessages = {
          401: "Неверные данные для авторизации",
          403: "Недостаточно прав",
        };

        setError(errorsMessages[res.response.status]);
        setIsError(true);
      });
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  return (
    <Layout title={`Редактировать лицензионное соглашение`}>
      <LicenseAgreementView
        licenseText={licenseText}
        setLicenseText={setLicenseText}
        handleUpdateLicense={handleUpdateLicense}
        isError={isError}
        error={error}
        handleCloseIsError={handleCloseIsError}
      />
    </Layout>
  );
};

export default licenseAgreement;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useKeys from "../../../hooks/useKeys";
import Layout from "../../../layouts/Layout";
import UserIdView from "../../../views/Users/UserIdView";

const UserCard = () => {
  const searchParams = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const { user, adminGetProfile } = useUser();
  const { getUserActiveKey } = useKeys();

  useEffect(() => {
    if (user) {
      adminGetProfile(searchParams.id)
        .then(res => {
          setUserInfo(res.data);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            400: "Пользователь не найден",
            404: "Пользователь не найден",
          };

          setError(errorsMessages[res?.response?.status]);
          setIsError(true);
        });
    }
  }, [user, searchParams.id]);

  useEffect(() => {
    if (userInfo) {
      getUserActiveKey(userInfo._id)
        .then(res => {
          setActiveKey(res.data);
        })
        .catch(res => {});
    }
  }, [userInfo]);

  return (
    <Layout
      title={`Пользователь: ${userInfo?.name ? userInfo.name : "Загрузка..."}`}
    >
      <UserIdView
        user={userInfo}
        setUserInfo={setUserInfo}
        activeKey={activeKey}
        isError={isError}
        setIsError={setIsError}
        error={error}
        setError={setError}
      />
    </Layout>
  );
};

export default UserCard;

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useUser from "../../hooks/useUser";
import {
  Box,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import UsersTable from "../../components/UsersTable/UsersTable";
import CloseIcon from "@mui/icons-material/Close";

const UsersView = () => {
  const navigate = useNavigate();
  const [orderDirection, setOrderDirection] = useState("asc");
  const [direction, setDirection] = useState(1);
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [role, setRole] = useState("");

  const [userInfo, setUserInfo] = useState(null);
  const [users, setUsers] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const itemsTableHeadRows = [
    {
      name: "Имя",
      value: "name",
    },
    {
      name: "Организация",
      value: "organization",
    },
    {
      name: "Роль",
      value: "roles",
    },
    {
      name: "Email",
      value: "email",
    },
  ];
  const roles = ["", "user", "admin", "superAdmin"];

  const { user, getUserInfo, fetchUsers, logout } = useUser();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCheckUserCard = id => {
    navigate(`/user/${id}`);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  const handleChangeRole = e => {
    setRole(e.target.value);
  };

  useEffect(() => {
    if (user) {
      getUserInfo()
        .then(res => {
          setUserInfo(res.data);
        })
        .catch(res => {
          logout();
        });
    }
  }, [user]);

  useEffect(() => {
    if (userInfo) {
      fetchUsers(
        page,
        rowsPerPage,
        role,
        JSON.stringify({
          [valueToOrderBy]: direction,
        })
      )
        .then(res => {
          setUsers(res.data);
          setError("");
          setIsError(false);
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            404: "Пользователи не найдены",
          };

          setError(errorsMessages[res?.response?.status]);
          setUsers({});
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [
    userInfo,
    valueToOrderBy,
    orderDirection,
    page,
    rowsPerPage,
    role,
    isLoading,
  ]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        p: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          alignSelf: "flex-start",
          ml: "8%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography>Выберите для фильтрации:</Typography>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="multiple-role-label">Роль</InputLabel>
          <Select
            labelId="multiple-role-label"
            id="multiple-role"
            value={role}
            onChange={handleChangeRole}
            input={<OutlinedInput label="Роль" />}
            MenuProps={MenuProps}
          >
            {roles.map(role => (
              <MenuItem key={role} value={role} sx={{ height: "30px" }}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <UsersTable
        HeadRows={itemsTableHeadRows}
        BodyRows={users}
        onClickUser={handleCheckUserCard}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        setDirection={setDirection}
        valueToOrderBy={valueToOrderBy}
        setValueToOrderBy={setValueToOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default UsersView;

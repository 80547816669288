import Layout from "../../layouts/Layout";
import ProjectsView from "../../views/Projects/ProjectsView";

const AllProjects = () => {
  return (
    <Layout title={"Проекты"}>
      <ProjectsView />
    </Layout>
  );
};

export default AllProjects;

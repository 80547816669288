import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";

const SuperAdminCreate = () => {
  const navigate = useNavigate();
  const { createSuperAdmin } = useUser();

  useEffect(() => {
    createSuperAdmin()
      .then(res => {
        navigate("/authorization");
      })
      .catch(res => {
        navigate("/authorization");
      });
  }, []);

  return <div></div>;
};

export default SuperAdminCreate;

import { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Modal,
  TextField,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../hooks/useUser";
import ShowableInput from "../components/ShowableInput/ShowableInput";
import CloseIcon from "@mui/icons-material/Close";
import ModalWithButton from "../components/ModalWithButton/ModalWithButton";

const AddAdmin = () => {
  const { createAdmin } = useUser();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    repeatedPassword: "",
  });

  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = prop => event => {
    if (
      (prop === "password" || prop === "repeatedPassword") &&
      (event.target.value.match(/[а-я]+/) || event.target.value.match(/[А-Я]+/))
    ) {
      setError("Пароль не может содержать кирилицу");
    } else {
      setError("");
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setError("");
    setIsLoading(true);

    if (values.password === values.repeatedPassword) {
      await createAdmin(values)
        .then(res => {
          setIsLoading(false);
          setIsOpen(true);
          setValues({
            name: "",
            email: "",
            password: "",
            repeatedPassword: "",
          });
        })
        .catch(res => {
          const errorsMessages = {
            400: "Проверьте поля",
            403: "Не достаточно прав",
            409: "Email занят",
          };
          setError(errorsMessages[res.response?.status]);
          setIsLoading(false);
        });
    } else {
      setError("Пароли не совпадают");
      setIsLoading(false);
    }
  };

  const handleCloseIsOpen = () => {
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "80%",
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: {
              lg: "40vw",
              md: "50vw",
              sm: "80vw",
              xs: "100vw",
            },
            height: {
              sm: "55vh",
              xs: "60vh",
            },
            p: {
              sm: "50px",
              xs: "30px",
            },
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <TextField
                value={values.name}
                label="Имя"
                variant="outlined"
                placeholder={`Введите имя`}
                sx={{
                  width: "100%",
                }}
                onChange={handleChange("name")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <TextField
                value={values.email}
                label="Email"
                variant="outlined"
                placeholder={`Введите Email`}
                sx={{
                  width: "100%",
                }}
                onChange={handleChange("email")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <ShowableInput
                inputLabel={"Пароль"}
                id={"registration-password"}
                value={values.password}
                handleChange={handleChange("password")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <ShowableInput
                inputLabel={"Повторите пароль"}
                id={"registration-repeatedPassword"}
                value={values.repeatedPassword}
                handleChange={handleChange("repeatedPassword")}
              />
            </Box>

            <Box
              sx={{
                height: "10%",
              }}
            >
              {error && (
                <Typography
                  variant="h7"
                  sx={{
                    mb: "1rem",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>

            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              sx={{
                width: "60%",
                maxHeight: "15%",
                minHeight: "15%",
                backgroundColor: "#42a5f5",
              }}
              color="primary"
            >
              Добавить
            </LoadingButton>
          </form>
        </Box>
      </Box>

      <ModalWithButton open={isOpen} closeFunction={handleCloseIsOpen}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Администратор успешно добавлен
        </Typography>
      </ModalWithButton>
    </Box>
  );
};

export default AddAdmin;

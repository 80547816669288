import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import useProject from "../../hooks/useProject";
import ProjectsTable from "../../components/ProjectsTable/ProjectsTable";
import CloseIcon from "@mui/icons-material/Close";

const UserProjectsView = ({ userInfo }) => {
  const navigate = useNavigate();
  const [orderDirection, setOrderDirection] = useState("asc");
  const [direction, setDirection] = useState(1);
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [userProjects, setUserProjects] = useState(null);

  const itemsTableHeadRows = [
    {
      name: "Имя",
      value: "name",
    },
    {
      name: "Последнее обновление",
      value: "updatedAt",
    },
    {
      name: "Пользователь",
      value: "ownerName",
    },
    {
      name: "Организация",
      value: "organization",
    },
    {
      name: "Статус",
      value: "status",
    },
  ];

  const { fetchUserProjects } = useProject();

  useEffect(() => {
    if (userInfo) {
      fetchUserProjects(
        userInfo?._id,
        page,
        rowsPerPage,
        JSON.stringify({
          [valueToOrderBy]: direction,
        })
      )
        .then(res => {
          setUserProjects(res.data);
          setError("");
          setIsError(false);
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            404: "Проекты не найдены",
          };

          setError(errorsMessages[res.response.status]);
          setUserProjects({});
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [userInfo, valueToOrderBy, orderDirection, page, rowsPerPage, isLoading]);

  const handleCheckProjectCard = projectId => {
    navigate(`/project/${projectId}`);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: {
            md: "90%",
            sm: "85%",
          },
          ml: {
            md: 0,
            sm: "20px",
          },
          maxHeight: { md: "100%", sm: "85%" },
          overflow: "auto",
          alignContent: "center",
        }}
      >
        <ProjectsTable
          HeadRows={itemsTableHeadRows}
          BodyRows={userProjects}
          onClickProject={handleCheckProjectCard}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          setDirection={setDirection}
          valueToOrderBy={valueToOrderBy}
          setValueToOrderBy={setValueToOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserProjectsView;

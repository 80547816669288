import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import useUser from "../../hooks/useUser";
import useProject from "../../hooks/useProject";
import ProjectsTable from "../../components/ProjectsTable/ProjectsTable";
import ModalWithButton from "../../components/ModalWithButton/ModalWithButton";

const ProjectsView = () => {
  const [orderDirection, setOrderDirection] = useState("asc");
  const [direction, setDirection] = useState(1);
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [projects, setProjects] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const itemsTableHeadRows = [
    {
      name: "Имя",
      value: "name",
    },
    {
      name: "Последнее обновление",
      value: "updatedAt",
    },
    {
      name: "Пользователь",
      value: "ownerName",
    },
    {
      name: "Организация",
      value: "organization",
    },
    {
      name: "Статус",
      value: "status",
    },
  ];

  const { user, getUserInfo, logout } = useUser();
  const { fetchUsersProjects } = useProject();

  useEffect(() => {
    if (user) {
      getUserInfo()
        .then(res => {
          setUserInfo(res.data);
        })
        .catch(res => {
          logout();
        });
    }
  }, [user]);

  useEffect(() => {
    if (userInfo) {
      fetchUsersProjects(
        page,
        rowsPerPage,
        JSON.stringify({
          [valueToOrderBy]: direction,
        })
      )
        .then(res => {
          setProjects(res.data);
          setError("");
          setIsError(false);
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            400: "Проекты не найдены",
            404: "Проекты не найдены",
          };

          setError(errorsMessages[res?.response?.status]);
          setIsError(true);
          setProjects({});
          setIsLoading(false);
        });
    }
  }, [userInfo, valueToOrderBy, orderDirection, page, rowsPerPage, isLoading]);

  const handleCheckProjectCard = projectId => {
    navigate(`/project/${projectId}`);
  };

  const handleCheckUserCard = userId => {
    navigate(`/user/${userId}`);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: {
            lg: "90%",
            sm: "85%",
          },
          ml: {
            md: 0,
            sm: "20px",
          },
          mt: {
            md: 0,
            sm: "20%",
          },
          maxHeight: { lg: "90%", sm: "80%" },
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <ProjectsTable
          HeadRows={itemsTableHeadRows}
          BodyRows={projects}
          onClickUser={handleCheckUserCard}
          onClickProject={handleCheckProjectCard}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          setDirection={setDirection}
          valueToOrderBy={valueToOrderBy}
          setValueToOrderBy={setValueToOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>

      <ModalWithButton open={isError} closeFunction={handleCloseIsError}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {error}
        </Typography>
      </ModalWithButton>
    </Box>
  );
};

export default ProjectsView;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import useTransaction from "../../hooks/useTransaction";
import TransactionsTable from "../../components/TransactionsTable/TransactionsTable";
import CloseIcon from "@mui/icons-material/Close";

const UserTransactionsView = ({ user }) => {
  const [orderDirection, setOrderDirection] = useState("asc");
  const [direction, setDirection] = useState(1);
  const [valueToOrderBy, setValueToOrderBy] = useState("key");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const searchParams = useParams();
  const [transactions, setTransactions] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const itemsTableHeadRows = [
    {
      name: "Id",
      value: "_id",
    },
    {
      name: "Ключ",
      value: "key",
    },
    {
      name: "Пользователь",
      value: "ownerId",
    },
    {
      name: "Проект",
      value: "projectId",
    },
    {
      name: "Добавлен",
      value: "createdAt",
    },
    {
      name: "Обновлен",
      value: "updatedAt",
    },
    {
      name: "Статус",
      value: "status",
    },
  ];

  const { getUserTransactions } = useTransaction();

  useEffect(() => {
    if (user) {
      getUserTransactions(
        searchParams.id,
        page,
        rowsPerPage,
        JSON.stringify({
          [valueToOrderBy]: direction,
        })
      )
        .then(res => {
          setTransactions(res.data);
          setError("");
          setIsError(false);
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            400: "Транзакции не найдены",
            404: "Транзакции не найдены",
          };
          setError(errorsMessages[res?.response?.status]);
          setTransactions({});
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [user, valueToOrderBy, orderDirection, page, rowsPerPage, isLoading]);

  const handleCheckUserCard = userId => {
    navigate(`/user/${userId}`);
  };

  const handleCheckProjectCard = projectId => {
    navigate(`/project/${projectId}`);
  };

  const handleCheckTransactionCard = transactionId => {
    navigate(`/transaction/${transactionId}`);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: {
            md: "90%",
            sm: "85%",
          },
          ml: {
            md: 0,
            sm: "20px",
          },
          mt: {
            md: 0,
            sm: "20%",
          },
          maxHeight: { md: "50%", sm: "85%" },
          overflow: "auto",
          alignContent: "center",
        }}
      >
        <TransactionsTable
          HeadRows={itemsTableHeadRows}
          BodyRows={transactions}
          onClickUser={handleCheckUserCard}
          onClickProject={handleCheckProjectCard}
          onClickTransaction={handleCheckTransactionCard}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          setDirection={setDirection}
          valueToOrderBy={valueToOrderBy}
          setValueToOrderBy={setValueToOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserTransactionsView;

import Layout from "../layouts/Layout";
import AddAdmin from "../views/AddAdmin";

const addAdmin = () => {
  return (
    <Layout title={`Добавление администратора`}>
      <AddAdmin />
    </Layout>
  );
};

export default addAdmin;

"use client";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { ClientApiContext } from "../providers/ApiProvider";

const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  const { api } = useContext(ClientApiContext);

  const login = async values => {
    const response = await api.auth.signInEmail(values);
    if (response.data) {
      setUser(response.data);
    }

    return response;
  };

  const getUserInfo = async () => {
    const response = await api.auth.getUserInfo();

    return response;
  };

  const logout = async () => {
    setUser({});
  };

  const fetchUsers = async (id, page, limit, role, sort) => {
    const response = await api.admin.getList(id, page, limit, role, sort);

    return response;
  };

  const adminGetProfile = async id => {
    const response = await api.admin.adminGetProfile(id);

    return response;
  };

  const refreshTokens = async token => {
    if (token) {
      setUser({
        ...user,
        accessToken: token,
      });
    } else {
      setUser({});
    }
  };
  const createSuperAdmin = async () => {
    const response = await api.auth.signUpSuperAdmin();

    return response;
  };

  const createAdmin = async data => {
    const response = await api.admin.signUpAdmin(data);

    return response;
  };

  const adminUpdateProfile = async (id, data) => {
    const response = await api.admin.adminUpdateProfile(id, data);

    return response;
  };

  return {
    user,
    login,
    getUserInfo,
    logout,
    fetchUsers,
    adminGetProfile,
    refreshTokens,
    createSuperAdmin,
    createAdmin,
    adminUpdateProfile,
  };
};

export default useUser;
